<template>
  <section id="age">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5 class="color-gray py-3">{{ title_component.subtitle }}</h5>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <v-select 
              class="selvue-custom"
              :options="indicadores_list" 
              v-model="indicadores_selected"
              :clearable="false">
            </v-select>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="data_sucursales" 
              v-model="sel_sucursal"
              :clearable="false">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3 mt-lg-0">
        <h5 class="font-title-bold color-gray">Reconocimientos totales</h5>
        <div class="card card-shadow border-round-10 border-0 mb-3" v-for="(data, i) in rc_sucursal" :key="i">
          <div class="card-body">
            <h6 class="color-gray mb-0">{{ data.nombre_valor }}</h6>
            <div class="d-flex align-items-center">
              <div class="progress flex-fill me-2">
                <div 
                  class="progress-bar" 
                  :style=" 'width: ' + data.percentage + '%;'+'background-color: ' + colores[i] + ';'"
                  aria-valuemin="0" 
                  aria-valuemax="100">
                </div>
              </div>
              <h5 
                class="mb-0 font-title-bold" 
                :style="'color: ' + colores[i]">
                  {{ formatPercentage(data.percentage) }}%
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--     <div class="age">
      <div class="tabs">
        <SectionTabs :tabs="tab_component"/>
      </div>
      <div class="age-top">
        <div class="age-top-select">
          <h3>{{ title_component.subtitle }}</h3>
          <div class="select">
            <div class="select-container" :class="open_indicadores_select_list && 'active'">
              <div class="select-btn" @click="openSelectIndicadores">
                <div class="item">
                  {{ indicadores_selected }}
                </div>
                <div class="icon">
                  <font-awesome-icon :icon="
                      open_indicadores_select_list ? 'angle-up' : 'angle-down'
                    ">
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_indicadores_select_list">
                <div v-for="(indi, k) in indicadores_list" :key="k" class="list-item" @click="selectIndicador(indi)">
                  {{ indi.label }}
                </div>
              </div>
            </div>
            <div class="select-container age" :class="open_select_list == 'age' && 'active'" @click="openSelect('age')">
              <div class="select-btn">
                <div class="item">
                  {{ place_group_selected.nombre_sucursal }}
                </div>
                <div class="icon">
                  <font-awesome-icon :icon="open_select_list == 'age' ? 'angle-up' : 'angle-down'">
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_select_list == 'age'">
                <div v-for="(place, k) in place_group_list" :key="k" class="list-item" @click="selectPlaceGroup(place)">
                  {{ place.nombre_sucursal }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="age-top-filter">
          <div class="title">Filtrar por período:</div>
          <div
            class="select-container month"
            :class="open_select_list == 'month' && 'active'"
            @click="openSelect('month')"
          >
            <div class="select-btn">
              <div class="item">
                {{ month_selected.nombre }}
              </div>
              <div class="icon">
                <font-awesome-icon
                  :icon="open_select_list == 'month' ? 'angle-up' : 'angle-down'"
                >
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_select_list == 'month'">
              <div
                v-for="(month, k) in month_list"
                :key="k"
                class="list-item"
                @click="selectMonth(month)"
              >
                {{ month.nombre }}
              </div>
            </div>
          </div>
          <div
            class="select-container year"
            :class="open_select_list == 'year' && 'active'"
          >
            <div class="select-btn" @click="openSelect('year')">
              <div class="item">
                {{ year_selected }}
              </div>
              <div class="icon">
                <font-awesome-icon
                  :icon="open_select_list == 'year' ? 'angle-up' : 'angle-down'"
                >
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_select_list == 'year'">
              <div
                v-for="(year, k) in year_list"
                :key="k"
                class="list-item"
                @click="selectYear(year)"
              >
                {{ year }}
              </div>
            </div>
          </div>
          <div class="calendar-container d-flex" v-click-outside="handleCalendar">
            <div class="calendar" :class="calendar_side" v-if="show_calendar">
              <v-calendar color="blue" :max-date="max_date" :min-date="min_date" :attributes="attributes"
                v-model="date_init" @dayclick="onDayClick" />
              <div class="border-arrow" :class="calendar_side">
                <div class="arrow"></div>
              </div>
            </div>
            <div class="start" @click="openCalendar('start-date')">
              <span>{{ init_date }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div class="end unactive" v-if="init_date == 'Inicio'">
              <span>{{ end_date }}</span>
  
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div class="end" v-if="init_date != 'Inicio'" @click="openCalendar('end-date')">
              <span>{{ end_date }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="age-container">
        <div class="content-title">Reconocimientos totales:</div>
        <div class="charts">
          <div class="service-bar" v-for="(data, i) in rc_sucursal" :key="i">
            <div class="bar-title">{{ data.nombre_valor }}</div>
            <div class="bar-container">
              <div class="progress">
                <tippy arrow followCursor="initial" trigger="click" class="tippy-container">
                  <template v-slot:trigger>
                    <div id="progress-service" :style="
                        'width: ' +
                        data.percentage +
                        '%;' +
                        'background-color: ' +
                        colores[i] +
                        ';'
                      "></div>
                  </template>
                  <div class="recon-num">
                    <span>N° reconocimientos</span> <br />
                    <span>{{ data.cantidad }} </span>
                  </div>
                </tippy>
              </div>
              <div class="percentage" :style="'color: ' + colores[i]">
                {{ formatPercentage(data.percentage) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
// import SectionTitle from "../Section/SectionTitle.vue";
import { GET_SUCURSAL, GET_RECONOCIMIENTO_PLANTA } from "@/apollo/queries";
import SectionTabs from "../Section/SectionTabs.vue";

export default {
  components: {
    // SectionTitle,
    SectionTabs,
  },
  data() {
    return {
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-age",
        },
      ],
      indicadores_list: [
        { label: "Año", route: "recognize-year" },
        { label: "Gerencia", route: "recognize-management" },
        { label: "Planta", route: "recognize-age" },
        { label: "Top 20", route: "recognize-ranking" },
        { label: "Consultas por colaborador", route: "recognize-contributor" },
        { label: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Planta",
      open_indicadores_select_list: false,

      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle:
          "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      open_select_list: "",
      year_list: ["2022", "2023", "2024", "2025"],
      year_selected: "2022",
      month_list: [
        { nombre: "Enero", id: "1" },
        { nombre: "Febrero", id: "2" },
        { nombre: "Marzo", id: "3" },
        { nombre: "Abril", id: "4" },
        { nombre: "Mayo", id: "5" },
        { nombre: "Junio", id: "6" },
        { nombre: "Julio", id: "7" },
        { nombre: "Agosto", id: "8" },
        { nombre: "Septiembre", id: "9" },
        { nombre: "Octubre", id: "10" },
        { nombre: "Noviembre", id: "11" },
        { nombre: "Diciembre", id: "12" },
      ],
      month_selected: { nombre: "Enero", id: "1" },
      place_group_list: [{ id_sucursal: "0", nombre_sucursal: "Todas" }],
      place_group_selected: { id_sucursal: "0", nombre_sucursal: "Todas" },
      rc_sucursal: [],
      colores: ["#357BF3", "#23BE61", "#F26F28", "#EC206B"],

      show_calendar: false,
      calendar_side: "",
      init_date: "Inicio",
      end_date: "Término",
      max_date: "",
      min_date: "",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),

      //FF
      sel_sucursal: { id: "0", type: "Todas", label: "Todas" },
      data_sucursales: [{ id: "0", type: "Todas", label: "Todas" }],
    };
  },
  created() {
    this.getSucursal(this.id_empresa);
},
  mounted() {
    this.end_date = this.dateNewFormat(new Date(),1);
    this.init_date = this.dateNewFormat(this.addDays(new Date(), -30),1) 
  },
  watch: {
  init_date(){
    this.getReconocimientoPlanta();
  },
  end_date(){
    this.getReconocimientoPlanta();
  },
  sel_sucursal() {
      this.getReconocimientoPlanta();
  },
  indicadores_selected(value) {
      this.$router.push({ name: value.route });
    }
},
computed: {
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  methods: {
    //obtener sucursales
    getSucursal(id) {
      this.place_group_list = [{ id_sucursal: "0", nombre_sucursal: "Todas" }];
      this.$apollo
        .query({
          query: GET_SUCURSAL,
          variables: {
            id_empresa: id,
          },
        })
        .then((res) => {
          var resp = res.data.sucursales;
          //console.log(resp);
          //resp.forEach((element) => this.place_group_list.push(element));
          resp.forEach((element) => this.data_sucursales.push({
            id: element.id_sucursal,
            type: element.__typename,
            label: element.nombre_sucursal
          }));
          this.getReconocimientoPlanta();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getReconocimientoPlanta() {
      this.$apollo
        .query({
          query: GET_RECONOCIMIENTO_PLANTA,
          variables: {
            id_empresa: this.id_empresa,
            fecha_inicio: this.init_date,
            fecha_final: this.end_date,
            //id_planta: this.place_group_selected.id_sucursal,
            id_planta: this.sel_sucursal.id,
          },
        })
        .then((res) => {
          var resp = res.data.reconocimientosPlantaYearMonth;
          this.rc_sucursal = resp;
          //console.log("getReconocimientoPlanta", resp);
          this.dataPercentage(resp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    dataPercentage(data) {
      var total = 0;
      data.forEach(function (element) {
        total = total + Number(element.cantidad);
      });
      data.forEach(function (element) {
        var el = Math.round((100 * Number(element.cantidad)) / total);
        element.percentage = isNaN(el) ? 0 : el;
      });
      //console.log(data);
    },
    openSelect(item) {
      if (item == this.open_select_list) {
        this.open_select_list = "";
      } else {
        this.open_select_list = item;
      }
    },
    selectYear(year) {
      this.year_selected = year;
      setTimeout(() => {
        this.open_select_list = "";
      }, 200);
      this.getReconocimientoPlanta();
    },
    selectMonth(month) {
      this.month_selected = month;
      setTimeout(() => {
        this.open_select_list = "";
      }, 200);
      this.getReconocimientoPlanta();
    },
    selectPlaceGroup(pla) {
      this.place_group_selected = pla;
      setTimeout(() => {
        this.open_select_list = "";
      }, 200);
      this.getReconocimientoPlanta();
    },
    formatPercentage(p) {
      if (p % 1 == 0) {
        return p;
      } else {
        return p.toFixed(1);
      }
    },
    openSelectIndicadores() {
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    selectIndicador(indi) {
      if (this.indicadores_selected != indi.name) {
        this.$router.push({ name: indi.route });
        this.indicadores_selected = indi.name;
      }
      this.open_indicadores_select_list = false;
    },
    
    
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.init_date != "Inicio" ? new Date(this.init_date) : "";
        this.max_date = this.end_date == "Término" ? "" : this.end_date;
        this.min_date = "";
      } else {
        var min = new Date(this.init_date);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.init_date == "Inicio" ? "" : this.init_date;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" || this.end_date != "Término") {
        this.getReconocimientoPlanta();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
    addDays(date, days) {
      var result = date;
      result.setDate(result.getDate() + days);
      return result;
    },
    dateNewFormat(date,type) {
      var this_day = date;
      var day = this_day.getDate();
      var month = this_day.getMonth();

      if(type == 1){
      return `${this_day.getFullYear()}-${
        month + 1 < 10 ? `0${month+ 1}` : month+ 1 
        }-${day + 1 < 10 ? `0${day+ 1}` : day+ 1}`;
      }
    },
  },
};
</script>
